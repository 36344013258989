import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`横山裕一`}</h1>
    <p>{`ディズニーや子ども向けテレビ番組のショーが大好きな川戸由紀さん。登場するキャラクターや舞台の様子を、一コマ一コマ描き出しています。紙に描かれたテレビショーは、手の中でパラパラ漫画のようにめくってみたくなるでしょうか。何百、何千枚と積み重なっているのは、舞台の照明が変化する様子を描いた布の作品です。そのどっしりとした存在感は、日々黙々と筆を走らせる川戸さんの姿を想像させるかもしれません。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1274px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "74.6875%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgD/2gAMAwEAAhADEAAAAeky55Wkhf8A/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxEhMf/aAAgBAQABBQL1vhqklhZbKFjP/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECESH/2gAIAQMBAT8BVzeCP//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAECAQE/AYf/xAAZEAEAAwEBAAAAAAAAAAAAAAAAARFRITH/2gAIAQEABj8Cdha2xiPH/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFhkaH/2gAIAQEAAT8hOgOsYX4YEA+soLbMx58nG0Ap0b//2gAMAwEAAgADAAAAEKPv/8QAGREBAAIDAAAAAAAAAAAAAAAAAQARIUGh/9oACAEDAQE/ELQ0iUwdn//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxBDTIX/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTFR/9oACAEBAAE/EPVFIJX0EqOaUb4bhpwmh1GU7OvrVktUnShiVvM7KQRboBrmVP/Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/ecfbd3de766d4d2a3bc9d783962a2f5d/ec4f2/yuichi-yokoyama.webp 320w", "/static/ecfbd3de766d4d2a3bc9d783962a2f5d/74ed4/yuichi-yokoyama.webp 640w", "/static/ecfbd3de766d4d2a3bc9d783962a2f5d/4edd4/yuichi-yokoyama.webp 1274w"],
          "sizes": "(max-width: 1274px) 100vw, 1274px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/ecfbd3de766d4d2a3bc9d783962a2f5d/9328e/yuichi-yokoyama.jpg 320w", "/static/ecfbd3de766d4d2a3bc9d783962a2f5d/f24a5/yuichi-yokoyama.jpg 640w", "/static/ecfbd3de766d4d2a3bc9d783962a2f5d/e6413/yuichi-yokoyama.jpg 1274w"],
          "sizes": "(max-width: 1274px) 100vw, 1274px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/ecfbd3de766d4d2a3bc9d783962a2f5d/e6413/yuichi-yokoyama.jpg",
          "alt": "yuichi yokoyama",
          "title": "yuichi yokoyama",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <Caption before={`作品`} medium={`漫画　2ページ、2020年`} mdxType="Caption">
  《ベビーブーム「花火」》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/yuki-kawado`} mdxType="LinkButton">応答する人: 川戸由紀</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      